import { jsx as _jsx } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import useMixpanelService from 'src/hooks/useMixpanelService';
import { AuthModalProvider } from 'src/context/AuthModalContext';
import { WidthProvider } from 'src/context/SideBarWidthContext';
import { ThemeProvider } from 'src/context/ThemeContext';
import { router } from 'src/router';
import { useGetCurrentUser, useGetCurrentUserApiKey } from './hooks/user';
function App() {
    useEffect(() => {
        var _a;
        ReactGA4.initialize((_a = process.env.REACT_APP_GOOGLE_ANALITYCS_KEY) !== null && _a !== void 0 ? _a : 'G-F1DH5D2S6B');
    }, []);
    useGetCurrentUser();
    useGetCurrentUserApiKey();
    useMixpanelService();
    return (_jsx(AuthModalProvider, { children: _jsx(ThemeProvider, { children: _jsx(WidthProvider, { children: _jsx(SnackbarProvider, { maxSnack: 3, autoHideDuration: 3000, action: (snackbarId) => (_jsx(CloseIcon, { onClick: () => closeSnackbar(snackbarId), style: { cursor: 'pointer' } })), children: _jsx(RouterProvider, { router: router }) }) }) }) }));
}
export default App;
